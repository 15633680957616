import React from "react";

const MainPage: React.FC = () => {
    return (
        <div>
            <h1>Main Page</h1>
        </div>
    );
}

export default MainPage;